* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  box-sizing: border-box;
}

html {
  // Default font size to 10px
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

body {
  box-sizing: border-box;
}

#root {
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'Moontime';
  src: local('Moontime'), url(../../fonts/MoonTime.ttf) format('truetype');
}

@font-face {
  font-family: 'Panama';
  src: local('Panama'), url(../../fonts/Panama.ttf) format('truetype');
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
}

.flex-center { justify-content: center; }
.flex-start { justify-content: flex-start; }
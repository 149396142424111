.strands.image-popup__background {
  overflow: hidden;
}

.image-popup {
  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(black, 0.95);
    z-index: 999;
    max-width: 100%;
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    cursor: pointer;

    & img {
      max-height: 100%;
      max-width: 100%;
      cursor: default;
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    height: 25px;
    width: 25px;
    cursor: pointer;
  }

  &__material-container{
    height: 100%;
    width: 100%;
    margin: 2rem;
    overflow: auto;
  }
}
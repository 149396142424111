.about {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 100%;
  background: #E3E2DF;
  color: $color-light-1;
  // padding: 40px 80px;
  padding: 40px 140px;
  // border-bottom: 1px solid $color-dark-1;

  &__border {
    &--bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 90%;
      border: 1px solid $color-light-1;
      // transform: translateX(-50%);
    }
  }

  &__title {
    font-size: 2.5rem;
    width: 100%;
    font-weight: 700;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 108rem;
    font-size: 18px;

    &-welcome {
      // font-size: 3.2rem;
      width: 100%;
      font-size: 5rem;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    &-statement {
      padding-top: 2rem;
      // font-size: 1.8rem;
      font-size: 2.4rem;
      // line-height: 2.4rem;
      line-height: 3rem;
      width: 100%;
    }

    &--left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      height: 100%;
      padding-right: 8rem;
      border-right: 1px solid $color-dark-1;
      // font-weight: 700;
    }
  }
}
.contact {
  position: relative;
  padding: 5.4rem 0 0 0;
  background: #E3E1EC;
  color: black;
  
  @include respond(big-desktop) {
    padding: 5.4rem 0 13rem 0;
    height: 90rem;
  }

  &__header {
    display: flex;
    position: relative;
    padding: 4em 0;
    align-items: center;

    @include respond(big-desktop) {
      height: 15.2rem;
      padding: 0;
    }
  }

  &__body {
    padding-top: 3em;
    position: relative;

    @include respond(tab-port) {
      padding-top: 4em;
    }
  }

  &__title {
    position: absolute;
    left: 0;
    font-size: 5rem;
    font-weight: 700;

    @include respond(tab-port) {
      font-size: 7.2rem;
    }

    @include respond(tab-land) {
      font-size: 10.5rem;
    }

    @include respond(big-desktop) {
      font-size: 11.5rem;
    }
  }

  &__content { 
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    @include respond(tab-port) {
      flex-direction: row;
    }

    @include respond(big-desktop) {
      flex-direction: row;
    }

    &-column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
 
    &-title {
      font-size: 3.2rem;
      font-weight: 100;
      text-transform: lowercase;

      @include respond(tab-land) {
        font-size: 4.2rem;
      }

      @include respond(big-desktop) {
        font-size: 5.2rem;
      }
    }

    &-location {
      font-size: 1.9rem;
      font-weight: 300;
      margin-bottom: 2em;
      position: relative;

      @include respond(tab-port) {
        flex: 1;
      }

      @include respond(tab-land) {
        font-size: 1.6rem;
        margin-bottom: 0;
      }

      @include respond(big-desktop) {
        font-size: 2.4rem;
      }
  
      &-item {
        line-height: 1.6;

        &:last-child {
          font-size: 2em;
        }
      }

      &-directions {
        margin-top: 4.5rem;
        font-size: 1.6rem;
        font-weight: 700;
        cursor: pointer;
        text-decoration: none !important;
        color: black;

        @include respond(tab-land) {
          position: absolute;
          bottom: 1rem;
        }
      }
    }

    &-hours {
      font-size: 2.4rem;
      font-weight: 300;

      @include respond(tab-port) {
        flex: 1;
        padding-left: 2em;
      }

      @include respond(tab-land) {
        font-size: 1.6rem;
      }

      @include respond(big-desktop) {
        padding-left: 0;
        font-size: 2.4rem;
      }
  
      & div {
        line-height: 1.6;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      color: black;
      // height: 60rem;
      width: 100%;
      background: $color-light-1;
      padding: 2em;
      z-index: 9;
      border-radius: .4rem;
      

      @include respond(tab-port) {
        padding: 2em 5em;
      }

      @include respond(big-desktop) {
        position: absolute;
        top: -2.6rem;
        right: 10rem;
        height: 60rem;
        width: 45%;
        padding: 5rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }
    }
  }

  &__hours {
    text-transform: capitalize;
    font-size: 1.9rem;

    @include respond(big-desktop) {
      font-size: 2.4rem;
    }
  }

  &__image {
    width: 1000px;
    object-fit: contain;

    @include respond(tab-land) {
      width: 100%;
    }

    @include respond(big-desktop) {
      object-fit: fill;
      width: auto;
    }

    &-container {
      width: 100%;
      position: relative;
      object-fit: contain;
      // height: 500px;
      overflow: hidden;

      @include respond(tab-land) {
        background-size: cover;
        height: 40rem;
        overflow: hidden;
      }

      @include respond(big-desktop) {
        height: 50rem;
        background-size: cover;
        object-fit: fill;
      }
    }
  }
}
.offers {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(black, 0.8);
  z-index: 999;

  &__container {
    width: 77rem;
    height: 50rem;
    background: black;
    border: 2px solid rgba(white, 0.25);
    color: $color-light-1;
    padding: 5rem;
  }

  &__content {
    width: 100%;
    text-align: center;
    padding-top: 5%;
    font-size: 2.4rem;
    font-weight: 100;
    
    & div {
      &:not(:last-child) {
        margin-bottom: 2%;
      }
    }


    &-container {
      height: 100%;
      width: 100%;
      border: 1px solid rgba($color-light-1, 0.25);
    }
  }

  &__title {
    width: 100%;
    text-align: center;
    padding-top: 5%;
    font-size: 3.2rem;
  }

  &__accept {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    height: 5rem;
    margin: 2% auto 4% auto;
    border-radius: .2rem;
    background: #1397FF;
    cursor: pointer;

    &:hover {
      background: darken(#1397FF, 10%);
    }

    &-text {
      text-align: center;
      font-size: 1.8rem;
    }
  }

  &__expiration {
    width: 100%;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 100;
  }
}
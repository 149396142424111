.divider {
  height: 40rem;
  background-size: 100%;
  background-position: center;
  border-top: .5rem solid $color-light-2;
  border-bottom: .5rem solid $color-light-2;

  &__image {
    width: 100%;
  }
}
.footer {
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 10;
  padding: 5rem;

  @include respond(big-desktop) {
    padding: 5rem 10rem;
  }

  &__logo {
    width: 235px;
    margin-bottom: 20px;

    @include respond(tab-port) {
      width: 18rem;
    }
  }

  &__link {
    color: $color-light-1;
    font-size: 2.2rem;
    cursor: pointer;
    font-weight: 100;
    text-decoration: none;
    border-bottom: 1px solid black;

    @include respond(tab-land) {
      font-size: 2rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
    }

    @include respond(big-desktop) {
      font-size: 2.4rem;
    }

    &:hover {
      font-weight: 400;
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 4em;
      justify-content: space-between;
      border-bottom: 1px solid rgba($color-light-1, 0.25);

      @include respond(tab-land) {
        padding: 0 3rem 4rem 0;
      }

      @include respond(big-desktop) {
        padding: 0 15% 8rem 15%;
        flex-direction: row;
        justify-content: space-between;
      }

      & a {
        color: white;
        text-decoration: inherit;
      }
    }

    &--active {
      font-weight: 400;
      // color: $color-light-2;
      // border-bottom: 1px solid $color-light-2;
    }
  }

  &__book-now {
    background-color: #0F0F0F;
    font-size: 1.8rem;
    padding: .8rem 1.6rem;
    text-decoration: none;
    color: $color-light-1;
  }

  &__social {
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 0 4rem 0;
    color: $color-light-1;

    @include respond(tab-land) {
      flex-direction: row;
    }

    &-container {
      text-align: center;
    }

    &-item {
      display: flex;
      flex: 1;
      height: 2.4rem;
      color: white;

      &-container {
        display: flex;
      }

      @include respond(tab-land) {
        &:not(:first-child) {
          margin-left: 3rem;
          margin-bottom: 0;
        }
      }

      @include respond(big-desktop) {
        margin-bottom: 0;
      }
      
      & a {
        margin-left: 1rem;
      }
    }
  }

  &__copyright {
    width: 100%;
    text-align: center;
    height: 5rem;
    font-size: 1.6rem;
    font-weight: 100;
    color: $color-light-1;
  }
}
.strands {
  // padding-top: 200px;
  // padding: 200px 10em 0px 10em;
  font-family: 'Raleway';

  &__book {
    font-size: 3.2rem;
    margin-top: 50px;
    color: black;
    border: .25px solid black;
    padding: .5em 1em;
    cursor: pointer;
    transition: $transition;

    &:hover {
      background-color: black;
      color: white;
    }

    &-section {
      font-size: 4.8rem;
      font-weight: 600;
      padding: 1em 0;
      border-top: 1px solid;
      border-bottom: 1px solid;
      text-align: center;
      cursor: pointer;
      background-color: #DAD4D0;
      color: white;

      & span {
        color: black;
      }
    }
  }

  &__header {
    min-height: 300px;
    overflow: hidden;

    @include respond(tab-land) {
      height: auto;
    }

    @include respond(big-desktop) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      height: calc(100vh - 200px);
    }

    &-image {
      display: flex;
      justify-content: center;

      & img {
        width: 250px;
      }
    }

    &-text {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: inherit;
        box-shadow: $shadow-1;
        padding: 4em;

        @include respond(tab-port) {
          background-color: white;
        }
      }

      &--primary {
        display: none;
        text-align: center;
        margin-bottom: 35px;
        font-size: 4.8rem;

        @include respond(tab-land) {
          font-size: 6.2rem;
        }
      }

      &--secondary {
        font-size: 2.4rem;
        font-weight: 400;
        margin-bottom: 30px;
      }

      &--tertiary {
        font-size: 1.8rem;
        font-weight: 300;
        text-align: center;
      }
    }
    
    &--left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #DAD4D0;
      z-index: 3;

      @include respond(tab-port) {
        padding: 3em;
      }

      @include respond(tab-land) {
        padding: 0 10em;
        // width: 100%;
        padding: 10em;
      }

      @include respond(big-desktop) {
        // width: 50%;
        padding: 0 10em;
      }
    }

    &--right{
      display: flex;
      align-items: center;
      position: relative;
      background-color: #DAD4D0;

      & img {
        max-width: 100%;
        border-top: 1px solid black;

        @include respond(big-desktop) {
          
        position: absolute;
          top: 0;
          right: -125px;
          max-height: 100%;
          max-width: none;
          border-top: none;
        }
      }
    }
  }

  &__questions {
    padding: 5em;

    @include respond(tab-port) {
      padding: 3em 5em;
    }

    @include respond(tab-land) {
      padding: 5em 7.5em;
    }
  
    @include respond(big-desktop) {
      padding: 10em 15em;
    }
  }

  &__image {
    &-type {
      margin-top: 2rem;
      font-size: 1.8rem;
      font-weight: 300;
      font-family: 'Raleway';
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid;
      height: 50px;
      cursor: pointer;
      width: 100%;
    }
  }

  &__material {
    display: none;
    justify-content: space-between;
    padding: 1em 0;
    font-size: 1.4em;

    @include respond(tab-port) {
      display: flex;
    }

    & p {
      font-family: 'Raleway';
      font-weight: 600;
    }
  }

  &__mid {
    padding: 5em;
    border-bottom: 1px solid;

    @include respond(tab-port) {
      padding: 10em 0em;
    }

    @include respond(tab-land) {
      padding: 10em 15em;
    }

    &-text {
      margin: 0 auto;
      font-size: 3.2rem;
      margin-bottom: 2em;

      & p { 
        font-family: 'Raleway';
        text-align: center;
      }
    }

    &-image {
      display: none;
      justify-content: center;

      @include respond(tab-port) {
        display: flex;
      }

      
      &-container {
        overflow: hidden;
        max-width: 245px;
        align-self: center;
        width: 100%;
      }

      & img {
        height: 400px;
      }
    }

    &-images {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      justify-items: center;

      @include respond(tab-port) {
        margin: 0 15rem;
      }

      @include respond(tab-land) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 0;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    border-top: 1px solid black;

    &-header {
      font-size: 3.2rem;
      margin-bottom: 1em;

      @include respond(tab-port) {
        font-size: 4.8rem;
      }

      & span {
        font-size: 7.2rem;
        line-height: 0;
        font-family: 'Moontime';
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      @include respond(tab-land) {
        flex-direction: row;
      }

      &--2 {
        padding: 0 15em;
      }
    }

    &-title {
      font-size: 4.8rem;
      text-align: center;
    }

    &-description {
      font-size: 2.4rem;
      text-align: center;

      & span {
        font-family: 'Moontime';
        font-size: 4.8rem;
      }
    }

    &-image {
      display: flex;
      flex: 1;
      justify-content: center;
      margin-bottom: 3em;

      @include respond(tab-land) {
        padding-right: 2em;
        margin-bottom: 0;
      }

      &--2 {
        height: 500px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        overflow: hidden;
  
        & img {
          width: 100%;
        }
      }
  
      & img {
        height: 500px;
        border: 1px solid black;

        @include respond(tab-port) {
          height: 650px;
        }
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      font-size: 0.8rem;

      &--2{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin: 0 8em;
      }
    }

    &-question {
      font-size: 2rem;
      padding-bottom: 1rem;
      font-weight: 500;

      @include respond(tab-port) {
        // padding-bottom: .2em;
        font-size: 2.4rem;
      }

      & span {
        font-size: 3.6rem;
        font-family: 'Moontime';
        line-height: 0;
        font-weight: 400;
      }
    }

    &-answer {
      font-size: 1.6rem;
      font-weight: 300;

      @include respond(tab-port) {
        font-size: 1.8rem;
      }
      
      & span {
        font-size: 3.2rem;
        font-family: 'Moontime';
        line-height: 0;
      }
    }

    &-section {

      @include respond(big-desktop) {
        padding-right: 10em;
      }

      &:not(:last-child) {
        padding-bottom: 3em;
      }

      &--2 {
        display: flex;
        
        &:not(:last-child) {
          padding-bottom: 5em;
        }
      }
    }
  }

  &__images {
    padding: 1em;

    @include respond(tab-port) {
      padding: 3em;
    }

    @include respond(big-desktop) {
      padding: 10em 15em;
    }
  
    &-header {
      font-family: 'Moontime';
      font-size: 8.4rem;

      @include respond(tab-land) {
        margin-bottom: 3.2rem;
      }
    }

    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; 
      grid-auto-rows: 1fr;
      grid-gap: 5px;

      @include respond(tab-land) {
        padding-bottom: 15em;
      }

      @include respond(tab-land) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @include respond(big-desktop) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }

  &__image-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 1em;
    border: 1px solid black;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1em;
    }

    & img {
      transition: all .3s cubic-bezier(.25, .8, .25, 1);
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    & img:hover {
      transform: scale(1.2);
    }
  }
}
.main {
  width: 100%;
  position: relative;

  &__content {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background-image: linear-gradient(to bottom, #DFDDEA,#E3E1EC);

    @include respond(tab-land) {
      height: 50%;
      width: 100%;
      background-image: none;
    }

    @include respond(big-desktop) {
      height: 100%;
    }

    &-container {
      color: black;
      padding: 2.4em;

      @include respond(tab-land) {
        position: relative;
        bottom: 0;
        text-align: center;
        padding: 0 10%;
        margin-top: 5rem;
      }

      @include respond(big-desktop) {
        padding: 10% 5% 0 5%;
        padding-right: 10%;
      }
    }

    &-welcome {
      font-weight: 300;
      width: 100%;
      font-size: 3rem;
      padding: .5em .5em 1em .5em;
      text-align: center;

      @include respond(big-desktop) {
        padding: 2rem 0 1rem 0;
        font-size: 4.6rem;
        display: flex;
        justify-content: center;
      }

      & div {
        @include respond(big-desktop) {
          margin-left: 10px;
        }
      }

      & span {
        font-style: italic;
        font-weight: 400;
      }
    }

    &-statement {
      text-align: center;
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 3rem;
      width: 100%;

      & span {
        font-style: italic;
        font-weight: 500;
      }

      @include respond(tab-port) {
        font-size: 2.1rem;
      }

      @include respond(big-desktop) {
        font-size: 2.4rem;
        padding-top: 2rem;

      }
    }

    &--left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      height: 100%;
      padding-right: 8rem;
      border-right: 1px solid $color-dark-1;
      // font-weight: 700;
    }
  }

  &__border {
    &--bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90%;
      border: 1px solid $color-light-1;
    }
  }

  &__body {
    width: 100%;

    @include respond(tab-land) {
      display: flex;
      background-image: linear-gradient(to top, #E3E1EC, #F6F0F4, #F9EEEC);
    }

    &-container {
      color: $color-light-2;
    }    
  }

  &__logo {
    width: 100%;
    object-fit: fill;

    @include respond(tab-land) {
      // width: 75%;
      max-height: 100vh;
      margin-left: 6em;
    }

    &-container {
      width: 100%;
      height: 100%;
      background: #DFDDEA;

      @include respond(tab-land) {
        width: 60%;
        background-image: linear-gradient(to top, #E3E1EC, #F6F0F4, #F9EEEC);
      }
    }
  }
}
.header {
  background: #FFF;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  height: 200px;

  @include respond(tab-land) {
    display: flex;
  }

  &__container {
    display: none;
    width: 100%;
    height: 200px;

    @include respond(tab-land) {
      display: block;
    }
  }

  &__logo {
    width: auto;
    height: auto;

    @include respond(tab-port) {
      max-width: 18rem;
    }

    @include respond(tab-land) {
      max-width: 20%;
    }

    &-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__strands {
    font-family: 'Moontime';
    text-transform: lowercase;
    font-size: 4.5rem !important;
    line-height: 0.5;
  }

  &__link {
    color: black;
    font-size: 2.4rem;
    cursor: pointer;
    font-weight: 100;
    text-decoration: none;
    text-align: center;

    @include respond(tab-land) {
      font-size: 2rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
    }

    &:hover {
      color: black;
      font-weight: 400;
    }

    &-container {
      display: flex;
      width: 100%;
      padding: 0 2%;
      justify-content: space-between;
      align-items: flex-end;

      @include respond(tab-land) {
        padding: 0%;
      }

      & a {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        margin: 0 1em;
        display: flex;
        justify-content: center;
        color: black;
        text-decoration: none;
      }
    }

    &--active {
      color: black !important;
      // border-bottom: 1px solid black;
      font-weight: 400;
    }
  }

  &__book-now {
    background-color: #0F0F0F;
    font-size: 1.8rem;
    padding: .8rem 1.6rem;
    text-decoration: none;
    color: $color-light-1;
  }
}

.mobile-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  padding: .8em 0;
  background: white;
  transition: all 0.5s ease;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @include respond(tab-land) {
    display: none;
  }

  &__container {
    height: 73px;
    @include respond(tab-port) {
      height: calc(12.5rem + (0.8em * 2));
    }
    @include respond(tab-land) {
      display: none;
    }
  }

  @include respond(tab-port) {
    // height: 12.5rem;
  }

  &--active {
    background-color: #DFDDEA !important;
  }

  &__main {
    position: relative;
    display: flex;

    @include respond(tab-port) {
      height: 12.5rem;
    }
  }

  &__button {
    color: black;
  }

  &__logo {
    position: absolute;
    max-height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    & a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  &__link {
    font-size: 3rem;
    padding: 1em 0;

    &:first-child {
      padding-top: 2em;
    }
  }
}








.services {
  position: relative;
  padding-top: 5.4rem;
  padding-bottom: 2rem;
  background: #E3E1EC;
  color: black;
  // border-top: .5rem solid black;

  @include respond(big-desktop) {
    padding-bottom: 8rem;
  }

  &__border {
    &--bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90%;
      border: 1px solid black;
      // transform: translateX(-50%);
    }
  }

  &__header {
    display: flex;
    position: relative;
    padding: 4em;
    align-items: center;

    @include respond(big-desktop) {
      height: 15.2rem;
      padding-left: 14rem;
    }
  }

  &__title {
    position: absolute;
    right: 0;
    font-size: 5rem;
    font-weight: 700;

    @include respond(tab-port) {
      font-size: 7.2rem;
    }

    @include respond(big-desktop) {
      font-size: 11.5rem;
    }
  }

  &__options {
    font-size: 2.5rem;
    margin-right: 2rem;
    cursor: pointer;
    border-bottom: 2px solid transparent;


    &:hover {
      color: black;
      border-bottom: 2px solid black;
    }

    &--active {
      color: black;
      border-bottom: 2px solid black;
    }
  }

  &__content {
    min-height: 690px;

    @include respond(big-desktop) {
      display: flex;
    }

    &-category {
      padding: .5em .5em;
      font-size: 2.4rem;
      font-weight: 300;
      cursor: pointer;

      @include respond(big-desktop) {
        padding: 3rem 5rem;
        font-size: 3.2rem;
        width: 390px;
      }

      &:hover {
        font-weight: 400;
      }

      &--active {
        font-weight: 400;
        border-bottom: 2px solid;

        @include respond(big-desktop) {
          border: none;
        }
      }

      &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include respond(tab-port) {
          padding: 2em 10em 0 10em;
        }
       
        @include respond(big-desktop) {
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }

    &-items-container {
      // padding: 6rem 4rem 8rem 8rem;
      padding: 3em .5em;
      // background-color: #222222;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: fit-content;
      border-bottom-left-radius: 4px;

      @include respond(tab-land) {
        padding: 3em 5em;
      }

      @include respond(big-desktop) {
        padding: 3em .5em;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content:flex-end;
      position: relative;
      color: $color-light-1;
      width: 100%;
      min-height: 125px;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
      overflow: hidden;
      border: 1px solid;
      border-color: rgba(black, 0.05);

      &:not(:last-child) {
        margin-bottom: .5em;
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        border-color: rgba(black, 0.05);
      }

      &:hover &-book {
        transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
        opacity: 1;
      }

      @include respond(tab-port) {
        width: calc(50% - .5em);
       
        &:nth-child(odd) {
          margin-right: 1em;
        }
      }

      @include respond(big-desktop) {
        width: calc(33% - .75em);

        &:not(:nth-child(3n)) {
          margin-right: 1em;
        }
      }

      &-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &-book {
        top: 0;
        right: 0;
        display: flex;
        position: absolute;
        height: 35%;
        width: 30%;
        background-image: linear-gradient(to top right, #5fdbfd 5%, #01BFF3);
        color: white;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 1.6rem;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
        display: none;

        @include respond(big-desktop) {
          display: block;
        }
      }

      &-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        filter: brightness(50%);
      }

      &-title {
        &-container {
          display: flex;
          flex-direction: column;
          // flex: 1;
          justify-content: space-between;
          font-size: 1.8rem;
          width: 40%;
          margin-bottom: .6em;
        }

        // transform: skewX(-30deg)
      }

      &-container {
        z-index: 1;
        background-color: rgba($color-light-1, 1);
        height: 100%;
        width: 100%;
        padding: 1.5em;
        color: $color-dark-1;
        display: flex;
        justify-content: space-between;
      }

      &-description {
        font-size: 1.2rem;
        // font-weight: 100;
      }

      &-price {
        display: flex;
        padding-top: .6em;
        font-size: 2.5rem;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
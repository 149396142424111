.products {
  position: relative;
  background-image: linear-gradient(to top, #E3E1EC, #F6F0F4, #F9EEEC);
  color: black;
  height: 100%;

  &__header {
    height: 7rem;
    position: relative;

    @include respond(tab-land) {
      height: 15.2rem;
    }
  }

  &__title {
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: 700;
    font-size: 5rem;

    @include respond(tab-land) {
      font-size: 10rem;
    }

    @include respond(big-desktop) {
      font-size: 11.5rem;
    }
  }

  &__item {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;

    & img {
      height: 100%;
      width: 100%;
    }

    @include respond(tab-land) {
      max-height: 40%;
      max-width: 40%;

      
      &-container:nth-child(odd) &-category {
        justify-content: flex-end;
      }
    }

    @include respond(big-desktop) {
      max-height: 30%;
      max-width: 30%;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        padding-bottom: 5rem;;
      }

      @include respond(tab-port) {
        padding: 0 2em;
      }

      @include respond(tab-land) {
        flex-direction: row;
        justify-content: space-between;

        &:nth-child(odd) {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }

      @include respond(big-desktop) {
        padding: 0 30em;
      }

    }

    &-category {
      font-size: 1.4rem;
      font-weight: 700;
      padding: 1em;
      display: flex;

      @include respond(tab-port) {
        font-size: 2.4rem;
      }

      @include respond(tab-land) {
        flex: 1;
      }
    }
  }
}